import './MyLessons.css'
import 'bootstrap/js/dist/collapse';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MyLessons = () => {
  const { user, setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [coursesData, setCoursesData] = useState({});

  const navigate = useNavigate();

  const loadCoursesData = () => {
    setIsLoading(true);

    const options = {
      method: "GET",
      url: `/api/v0/get_lessons/`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        setCoursesData(response.data)
      });
  };

  useEffect(() => {
    loadCoursesData();
  }, []);

  const handleSetScenario = (lesson, scenario) => {
    const data = {
        course: 'python_beginner',
        lesson,
        scenario,
      };
      const csrfToken = document.cookie.split('; ')
        .find(cookie => cookie.startsWith('csrftoken='))
        .split('=')[1];
    
      axios.post(
        '/api/v0/set_scenario/',
         data,
         {
          headers: {
            'X-CSRFToken': csrfToken,
          }
        })
        .then(response => {
          console.log(response.data);
          // We have only one course for now.
          navigate('/course/python_beginner/');
        })
  }

  const openProblem = (problemId) => {
    navigate(`/problem/${problemId}`)
  }

  return (
    <div>
        <section class="h-100 gradient-custom-2">
            <div class="container py-5 h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col col-lg-9 col-xl-7">
                <p class="lead fw-normal mb-1">Course: Python beginner</p>
                <div class="accordion" id="accordionCourse1">
                    {coursesData && coursesData['python_beginner'] && coursesData['python_beginner'].map((lesson, i) => (
                    <>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id={"headingLesson" + i}>
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseLesson" + i} aria-expanded="false" aria-controls={"collapseLesson" + i}>
                                    {lesson.lesson_slug}
                                </button>
                            </h2>
                            <div id={"collapseLesson" + i} class="accordion-collapse collapse" aria-labelledby={"headingLesson" + i} data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Lesson info</strong>
                                    {lesson.scenarios.map(scenario => (
                                        <>
                                        <div class="alert alert-primary d-flex mt-3 align-items-center">
                                            <div>{scenario.slug}</div>
                                            <a className="btn btn-outline-dark ml-auto mr-2" onClick={() => handleSetScenario(lesson.lesson_slug, scenario.slug)}>Запустить</a>
                                        </div>
                                        <div class="alert alert-primary d-flex mt-3 align-items-center">
                                            <div>Задача: {scenario.problem__id}</div>
                                            <a className="btn btn-outline-dark ml-auto mr-2" onClick={() => openProblem(scenario.problem__id)}>Открыть</a>
                                        </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>  
                    ))}
                </div>
                </div>
                </div>
            </div>
            </section>
    </div>
  );
};

export default MyLessons;