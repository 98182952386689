import './ConfirmPage.css'
import React, { useState, useEffect } from "react";
import axios from "axios";

import { useSearchParams } from 'react-router-dom';

const ConfirmPage = () => {
  // const email = 'ura@ya.ru';
  // const activation_code = 'en53jnq934jfh2fwuta6fp070ejmq5';

  const [searchParams] = useSearchParams();
  const [resultText, setResultText] = useState(null);  

  useEffect(() => {
    const registerData = {
      // email: email,
      activation_code: searchParams.get('code'),
    };
    // const csrfToken = document.cookie.split('; ')
    //   .find(cookie => cookie.startsWith('csrftoken='))
    //   .split('=')[1];
  
    axios.post(
      '/api/v0/confirm_email',
       registerData,
       {
        // headers: {
        //   'X-CSRFToken': csrfToken,
        // }
      })
      .then(response => {
        setResultText(response.data.message);
      })
  }, []);

  return (
    <div>
        <h1 class="h3 mb-3 font-weight-normal confirm-text">{resultText}</h1>
    </div>
  );
};

export default ConfirmPage;
