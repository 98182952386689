import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';

const storedLanguage = localStorage.getItem('language');
const userLanguage = navigator.language || navigator.userLanguage;

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ru: { translation: ruTranslation },
    // Add more languages and their translations here
  },
  lng: storedLanguage || userLanguage, // Use detected language as the default language
  fallbackLng: 'en', // Fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // React already escapes the values
  },
});

export default i18n;