import "prismjs/themes/prism-tomorrow.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import MainPage from "./components/MainPage/MainPage";
import Chat from "./components/Chat";
import Problem from './components/Problem/Problem';
import LoginPage from './components/LoginPage/LoginPage';
import RegisterPage from './components/RegisterPage/RegisterPage';
import ConfirmPage from './components/ConfirmPage/ConfirmPage';
import ProfilePage from './components/ProfilePage/ProfilePage';
import MyLessons from './components/MyLessons/MyLessons';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from './components/AuthContext';
import { useTranslation } from 'react-i18next';
import MainLogo from './mainlogo.png';
import MainLogoColor from './mainlogo_color.png';
import Prism from "prismjs";

function App() {
  const { user, setAuth } = useAuth();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [colorLogo, setColorLogo] = useState(false);

  useEffect(() => {
    Prism.languages.python = {
      'comment': /#[^\r\n]*(\r?\n|$)/,
      'string': {
          pattern: /(["'])(\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
          greedy: true,
      },
      'keyword': /\b(and|as|assert|async|await|break|class|continue|def|del|elif|else|except|False|finally|for|from|global|if|import|in|is|lambda|None|nonlocal|not|or|pass|raise|return|True|try|while|with|yield)\b/,
      'function': /\b\w+(?=\()/,
      'boolean': /\b(True|False)\b/,
      'number': /\b\d+\.?\d*\b/,
      'operator': /[-+*/%=]=?|!=|<=?|>=?|==?|@|&|\||\^|~|\*\*|\.\.|\b(and|or|not)\b/,
      'punctuation': /[{}[\];(),.:]/
  };
  }, []);


  const handleLogout = () => {
    const options = {
      method: "GET",
      url: `/api/v0/logout/`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios
      .request(options)
      .then(function (response) {
        setAuth(false);
        navigate('/');
      });
  }

  const handleLogin = () => {
    navigate('/login');
  };
  const handleRegister = () => {
    navigate('/register');
  };

  const handleProfile = (e) => {
    navigate('/profile');
  }

  const handleChangeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
    navigate(0);
  }

  const handleLogoMouseEnter = () => {
    setColorLogo(!colorLogo);
  };

  const handleContinue = (e) => {
    navigate('/course/python_beginner/');
  }

  const handleChooseLesson = (e) => {
    navigate('/lessons/');
  }

  return (
    <div className="d-flex flex-column h-100">
      <main className="flex-shrink-0">
          <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white border-bottom box-shadow">
              <a class="navbar-brand" href="/" style={{maxWidth: "150px"}}>
                <img src={colorLogo ? MainLogoColor : MainLogo} onMouseEnter={handleLogoMouseEnter}></img>
              </a>
              {user && 
                <nav className="my-2 my-md-0 mr-md-3 m-md-auto">
                  {user.current_course && (
                    <>
                      <a className="btn btn-outline-dark mr-2" onClick={handleContinue}><i class="bi bi-play-circle" /> {t('continue_lesson')}</a>
                      <a className="btn btn-outline-dark" onClick={handleChooseLesson}><i class="bi bi-card-list"></i> {t('choose_lesson')}</a>
                    </>
                  )
                  }
                </nav>
              }
              {user ?
                <div>
                  <select onChange={handleChangeLanguage} value={i18n.language} class="btn btn-outline-dark dropdown-toggle ml-auto mr-2">
                    <option value="en">EN</option>
                    <option value="ru">RU</option>
                  </select>
                  <a className="btn btn-outline-dark" onClick={handleProfile}>{t('my_profile')} ({user.name})</a>
                </div> :
                <div className="ml-auto">
                  <select onChange={handleChangeLanguage} value={i18n.language} class="selectpicker btn btn-outline-dark dropdown-toggle ml-auto mr-2">
                    <option value="en">EN</option>
                    <option value="ru">RU</option>
                  </select>
                  <a className="btn btn-outline-dark ml-auto mr-2" onClick={handleLogin}>{t('login')}</a>
                  <a className="btn btn-outline-dark ml-auto" onClick={handleRegister}>{t('create_account')}</a>
                </div>
              }
          </div>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/course/:course_slug" element={<Chat />} />
            <Route path="/problem/:problemId" element={<Problem />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/confirm_email" element={<ConfirmPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/lessons" element={<MyLessons />} />
              {/* <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="dashboard" element={<Dashboard />} />
            </Route> */}
          </Routes>
      </main>
    </div>
  )
}

export default App;
