import './Chat.css';

import React, { useEffect, useState, useRef } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import Footer from "./Footer";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import { TransitionGroup } from 'react-transition-group';
import { useParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

import Prism from "prismjs";

const Chat = () => {
  // const currentScenario = {
  //   scenario: [{
  //       id: 0,
  //       messages: [{
  //           person: 'Капитан Путо',
  //           text: 'Привет name! Как же я рад увидеть хоть кого-то на этой пустующей планете! Я сижу здесь уже почти целый год!',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar1.png',
  //       }],
  //       answer: {
  //           person: 'Fedor',
  //           text: 'Что случилось?',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar6.png',
  //           right: true,
  //       },
  //   },
  //   {
  //       id: 1,
  //       messages: [{
  //           person: 'Капитан Путо',
  //           text: 'Эх ... Когда-то я бороздил просторы космоса, видел так много планет, на моем корабле П-300 😢 А сейчас ... Мой давний враг Джав нашел меня и разрушил мой корабль и теперь я ничего не могу сделать. И самое главное - он забрал у меня единственный предмет, который у меня остался с моего дества на земле - футбольный мяч! Сейчас таких мячей не найти во всей галактике!',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar1.png',
  //       }],
  //       answer: {
  //           person: 'Fedor',
  //           text: 'И что делать?',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar6.png',
  //           right: true,
  //       },
  //   },
  //   {
  //       id: 2,
  //       messages: [{
  //           person: 'Капитан Путо',
  //           text: 'Мне нужно во чтобы то ни стало починить корабль и найти моего врага, пока он не проколол мяч или не продал его. Я вижу что ты смышленый ... Поможешь мне починить корабль? В нем поломалась вся техника и мне нужен инженер, который сможет запрограммировать бортовой компьютер ...',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar1.png',
  //       }],
  //       answer: {
  //           person: 'Fedor',
  //           text: 'А на каком языке программирования?',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar6.png',
  //           right: true,
  //       },
  //   }, {
  //       id: 3,
  //       messages: [{
  //           person: 'Капитан Путо',
  //           text: 'Python. Это очень удобный язык, который был изобретен ... (story). Но ты не переживай, я смогу научить тебя основам, которые помню, но память в старости меня сильно подводит и я не могу писать сложные программы, поэтому мне очень нужен помощник. Ну что, ты в деле? Попробуем завести П-300?',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar1.png',
  //       }],
  //       answer: {
  //           person: 'Fedor',
  //           text: 'Да!',
  //           avatarUrl: 'https://bootdey.com/img/Content/avatar/avatar6.png',
  //           right: true,
  //       },
  //   }]
  // }

  let { course_slug } = useParams();

  const listRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showProblemLink, setShowProblemLink] = useState(false);
  const [nextScenarioExists, setNextScenarioExists] = useState(false);

  const [allScenarios, setAllScenarios] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [currentScenarioIndex, setCurrentScenarioIndex] = useState(0);
  const [currentDialogInScenario, setCurrentDialogInScenario] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const loadAllCurrentScenarios = () => {
    setIsLoading(true);

    const options = {
      method: "GET",
      url: `/api/v0/current_scenarios/${course_slug}/`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response);
        setAllScenarios(response.data.current_scenarios);
        setShowProblemLink(false);
        setNextScenarioExists(false);
        
        // Добавляем все сообщения из предыдущих сценариев
        const dialogs = [];
        response.data.current_scenarios.forEach((scenario, i) => {
          if (i == response.data.current_scenarios.length - 1) {
            // Не добавляем сообщения из последнего сценария
            return;
          }
          dialogs.push(...scenario.dialogs);
        });

        const convertedMessages = [];
        dialogs.forEach(dialog => {
          convertedMessages.push(...dialog.messages);
          convertedMessages.push(dialog.answer);
        });
        
        // Индекс текущего сценария - это последний индекс в списке сценариев
        const csi = response.data.current_scenarios.length - 1;
        setCurrentScenarioIndex(response.data.current_scenarios.length - 1);

        // Добавляем первые сообщения и ответы из последнего сценария
        for (let i = 0; i <= response.data.current_dialog_id; i++ ) {
          convertedMessages.push(...response.data.current_scenarios[csi].dialogs[i].messages);
          if (i !== response.data.current_dialog_id) {
            convertedMessages.push(response.data.current_scenarios[csi].dialogs[i].answer);
          }
        }
        setCurrentMessages(convertedMessages);
        
        // Стартуем с текущего диалога в последнем сценарии
        setCurrentDialogInScenario(response.data.current_dialog_id);

        // если у нас только один диалог в последнем сценарии - надо сразу показывать задачу
        if (response.data.current_scenarios[csi].dialogs.length == response.data.current_dialog_id + 1) {
          setShowProblemLink(true);
        }
        
        if (response.data.next_scenario) {
          setNextScenarioExists(true);
        }

        setIsLoading(false);
        Prism.highlightAll();
      });
    }

  useEffect(() => {
    loadAllCurrentScenarios();
    Prism.highlightAll();
  }, []);

  const nextDialog = () => {
    // Нужно загрузить и перейти в следующий сценарий
    // setShowProblemLink(false);
    // setNextScenarioExists(false);

    // Переходим к следующему сценарию.
    if (currentDialogInScenario == allScenarios[currentScenarioIndex].dialogs.length - 1) {
      const options = {
        method: "GET",
        url: `/api/v0/go_to_next_scenario/${course_slug}/`,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          if (response.data.code_message == 'no_next_scenario') {
            setShowModal(true);
          } else {
            loadAllCurrentScenarios();
          }
        });

      return;
    }    

    console.log('cdis', currentDialogInScenario + 1);

    const registerData = {
      new_dialog_id: currentDialogInScenario + 1,
      course: 'python_beginner',
    };
    const csrfToken = document.cookie.split('; ')
      .find(cookie => cookie.startsWith('csrftoken='))
      .split('=')[1];
  
    axios.post(
      '/api/v0/set_current_dialog/',
       registerData,
       {
        headers: {
          'X-CSRFToken': csrfToken,
        }
      });

    // Мы на последнем диалоге в сценарии. Показываем ссылку на задачу.
    if (currentDialogInScenario == allScenarios[currentScenarioIndex].dialogs.length - 2) {
      // Если мы в самом конце - то проверяем есть ли у нас доступ к следующему сценарию
      //  - есть доступ к сценарию - показываем 2 кнопки - ответ и перейти на задачу
      //  - доступа нет - показываем 1 кнопку - перейти в задачу
      // переход в задачу открывается в новом окне
      // console.log("LAST DIALOG!!! NEED TO THINK ABOUT PROBLEM!");
      setShowProblemLink(true);
      setCurrentDialogInScenario(index => index + 1);
      setCurrentMessages(messages => [
          ...messages, 
          allScenarios[currentScenarioIndex].dialogs[currentDialogInScenario].answer, 
          ...allScenarios[currentScenarioIndex].dialogs[currentDialogInScenario + 1].messages
      ]);
      return;
    }

    setCurrentDialogInScenario(index => index + 1);
    setCurrentMessages(messages => [
        ...messages, 
        allScenarios[currentScenarioIndex].dialogs[currentDialogInScenario].answer, 
        ...allScenarios[currentScenarioIndex].dialogs[currentDialogInScenario + 1].messages
    ]);
  }

  const openProblem = () => {
    window.open(`/problem/${allScenarios[currentScenarioIndex].problem_id}`, "_blank")
  };

  useEffect(() => {          
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [currentMessages]);

  return (
    <>
    <div className="d-flex flex-column h-100">
        <div className="container content">
            <div className="row overflow-auto">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="card" style={{border: "none"}}>
                        <div className="card-body height3">
                            <ul className="chat-list" ref={listRef}>
                                {currentMessages.map((message, i) => {
                                    return (
                                        <li key={i} className={message.right ? "out" : "in"}>
                                            <div className="chat-img">
                                                <img alt="Avtar" src={message.avatarUrl} />
                                            </div>
                                            <div className="chat-body">
                                                <h5 style={{color: "black"}}>{message.person}</h5>
                                                <div className="chat-message">
                                                    <p className="lead" dangerouslySetInnerHTML={{__html: message.text}}></p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <footer className="footer mt-auto py-3 bg-light fixed-bottom">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-md-auto content">
                    {/* && (nextScenarioExists || currentDialogInScenario != allScenarios[currentScenarioIndex].dialogs.length - 1)  */}
                        {!isLoading && 
                          <button type="button" className="btn btn-primary mr-2" onClick={nextDialog}>
                              Ответить: {allScenarios[currentScenarioIndex].dialogs[currentDialogInScenario].answer.text}
                          </button>
                        }
                        {showProblemLink &&
                          <button type="button" className="btn btn-primary" onClick={openProblem}>
                              Перейти к задаче: {allScenarios[currentScenarioIndex].problem_id}
                          </button>
                        }
                    </div>
                </div>
            </div>
        </footer>

        <Modal show={showModal}>
            <Modal.Header>
                <Modal.Title>Нет доступа</Modal.Title>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                К сожалению, доступа дальше нет. Вернитесь к решению задачи и нажмите действие снова.
            </Modal.Body>
        </Modal>
    </div>
    </>
  );
};

export default Chat;
