import './LoginPage.css'
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

import MainLogo from '../../mainlogo.png';

import Modal from "react-bootstrap/Modal";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, setAuth } = useAuth();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { t } = useTranslation();
  

  const navigate = useNavigate();

  if (user) {
    navigate('/');
  }

  const handleCloseModal = () => {
    setShowErrorModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const loginData = {
      email: email,
      password: password
    };
    // const csrfToken = document.cookie.split('; ')
    //   .find(cookie => cookie.startsWith('csrftoken='))
    //   .split('=')[1];
  
    axios.post(
      '/api/v0/login',
       loginData,
       {
        // headers: {
        //   'X-CSRFToken': csrfToken,
        // }
      })
      .then(response => {
        console.log(response.data);
        if (response.data.code == 200) {
          setAuth(true);
          navigate('/');
        } else {
          setErrorText(response.data.message)
          setShowErrorModal(true);
        }
      })
  };

  return (
    <div>
      <form onSubmit={handleSubmit} class="h-100 d-flex flex-column align-items-center justify-content-center" style={{maxWidth: '50%', margin: 'auto'}}>
        {/* <img class="mb-4" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"/> */}
        <img class="mb-4" src={MainLogo} style={{maxWidth: "300px", marginTop: "30px"}}></img>
        <h1 class="h3 mb-3 font-weight-normal">Форма входа</h1>
        <label for="inputEmail" class="sr-only">Email address</label>
        <input 
          type="email" 
          id="inputEmail" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          class="form-control" 
          placeholder="Email address" 
          required 
          autofocus
        />
        <label for="inputPassword" class="sr-only">Password</label>
        <input 
          type="password" 
          id="inputPassword"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          class="form-control" 
          placeholder="Password" 
          required
        />
        <button class="btn btn-lg btn-dark btn-block" type="submit">Войти</button>
      </form>

      <Modal show={showErrorModal}>
        <Modal.Header>
          <Modal.Title>{t('error')}</Modal.Title>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
              <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>
        <Modal.Body>
          <p>{errorText}</p>
        </Modal.Body>
      </Modal>
    </div>    
  );
};

export default LoginPage;
