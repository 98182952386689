import './Problem.css';
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import CodeEditorWindow from ".././CodeEditorWindow";
import axios from "axios";
import { classnames } from "../../utils/general";
import { languageOptions } from "../../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import { useParams } from 'react-router-dom';

import { defineTheme } from "../../lib/defineTheme";
import useKeyPress from "../../hooks/useKeyPress";

import Modal from "react-bootstrap/Modal";

const javascriptDefault2 = `/**
* Problem: Binary Search: Search a sorted array for a target value.
*/

// Time: O(log n)
const binarySearch = (arr, target) => {
 return binarySearchHelper(arr, target, 0, arr.length - 1);
};

const binarySearchHelper = (arr, target, start, end) => {
 if (start > end) {
   return false;
 }
 let mid = Math.floor((start + end) / 2);
 if (arr[mid] === target) {
   return mid;
 }
 if (arr[mid] < target) {
   return binarySearchHelper(arr, target, mid + 1, end);
 }
 if (arr[mid] > target) {
   return binarySearchHelper(arr, target, start, mid - 1);
 }
};

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const target = 5;
console.log(binarySearch(arr, target));
`;

const javascriptDefault = `# Напишите ваш код здесь.
print("Hello world")
`;

const Problem = () => {
  const { i18n, t } = useTranslation();

  const [code, setCode] = useState(javascriptDefault);
  const [codeDefault, setCodeDefault] = useState("");
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[37]);
  const [submits, setSubmits] = useState([]);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [problemNotFound, setProblemNotFound] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const { problemId } = useParams();

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const loadSubmits = () => {
    setIsLoading(true);

    const options = {
      method: "GET",
      url: `/api/v0/get_problem/${problemId}?lang=${i18n.language}`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios
      .request(options)
      .then(function (response) {
        console.log('/get_submits', response.data);
        if (response.data.code == "200") {
          setSubmits(_ => [...response.data.submits]);
          setDescription(response.data.description);
          setCodeDefault(response.data.code_default);
        }
        if (response.data.code == '400') {
          setProblemNotFound(true);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadSubmits();
  }, []);


  console.log(languageOptions[37]);

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const tail = ([x,...xs]) => xs;

  const handleSubmit = () => {
    const registerData = {
      problem_id: problemId,
      code: code,
    };
    const csrfToken = document.cookie.split('; ')
      .find(cookie => cookie.startsWith('csrftoken='))
      .split('=')[1];
    axios.post(
      '/api/v0/submit/',
       registerData,
       {
        headers: {
          'X-CSRFToken': csrfToken,
        }
       }
      )
      .then(response => {
        if (!response.data.submit) {
          setSubmits(submits => [...tail(submits)]);
          setSubmitPending(false);
          setModalContent("request_submit_error");
          setShowModal(true);
        }
        setSubmits(submits => [response.data.submit, ...tail(submits)]);
        setSubmitPending(false);
        if (response.data.is_first) {
          setModalContent("congrats");
          setShowModal(true);
        }
        console.log(response);
      });
  }
  const handleCompile = () => {
    let newPendingSubmit = {
      status: 'pending',
    };

    setSubmitPending(true);
    setSubmits(submits => [newPendingSubmit, ...submits]);
    setTimeout(() => handleSubmit(), 2000);
    // let newPendingSubmit = {
    //   status: 'pending',
    // };
    // setSubmits(submits => [newPendingSubmit, ...submits]);
    // const registerData = {
    //   problem_id: "hello_world",
    //   code: code,
    // };
    // const csrfToken = document.cookie.split('; ')
    //   .find(cookie => cookie.startsWith('csrftoken='))
    //   .split('=')[1];
    // axios.post(
    //   '/api/v0/submit/',
    //    registerData,
    //    {
    //     headers: {
    //       'X-CSRFToken': csrfToken,
    //     }
    //    }
    //   )
    //   .then(response => {
    //     setSubmits(submits => [response.data.submit, ...tail(submits)])
    //     console.log(response);
    //   })
    // setProcessing(true);
    // const formData = {
    //   language_id: language.id,
    //   // encode source code in base64
    //   source_code: btoa(code),
    //   stdin: btoa(customInput),
    // };
    // const options = {
    //   method: "POST",
    //   url: process.env.REACT_APP_RAPID_API_URL,
    //   params: { base64_encoded: "true", fields: "*" },
    //   headers: {
    //     "content-type": "application/json",
    //     "Content-Type": "application/json",
    //     "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
    //     "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
    //   },
    //   data: formData,
    // };

    // axios
    //   .request(options)
    //   .then(function (response) {
    //     console.log("res.data", response.data);
    //     const token = response.data.token;
    //     checkStatus(token);
    //   })
    //   .catch((err) => {
    //     let error = err.response ? err.response.data : err;
    //     // get error status
    //     let status = err.response.status;
    //     console.log("status", status);
    //     if (status === 429) {
    //       console.log("too many requests", status);

    //       showErrorToast(
    //         `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
    //         10000
    //       );
    //     }
    //     setProcessing(false);
    //     console.log("catch block...", error);
    //   });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        showSuccessToast(`Compiled Successfully!`);
        console.log("response.data", response.data);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const problemContent = (
    <>
    <div className="flex flex-row">
          {/* <div className="px-4 py-2">
            <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
          </div> */}
          {/* <div className="px-4 py-2">
            <button 
              onClick={handleSubmit}
              className={classnames(
                "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                !code ? "opacity-50" : ""
              )}
            >
              Submit
            </button>
          </div> */}
        </div>
        <div className="flex flex-row space-x-4 items-start px-4 py-4">
          <div className="flex flex-col w-full h-full justify-start items-end">
            <CodeEditorWindow
              code={codeDefault}
              onChange={onChange}
              language={language?.value}
              theme={theme.value}
            />
          </div>
          <div className="right-container flex flex-shrink-0 w-[40%] flex-col">
            <h5>Условие задачи:</h5>
            <div>{description}</div>
            <button
                onClick={handleCompile}
                disabled={submitPending}
                className={
                  !submitPending ? 
                  "mt-4 mb-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-2 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0" : 
                  "mt-4 mb-4 border-2 border-black z-10 rounded-md px-2 py-2 hover:shadow flex-shrink-0 disabled"
                }
              >
                {submitPending && (<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>)}
                {submitPending ? "Processing..." : "Отправить задачу на проверку"}
            </button>
            <h5>Последние попытки:</h5>
            <div className="flex submit-container">
              {submits.length == 0 ? "You don't have submits yet." : ''}
              {submits.map((submit_data, i) => {
                let submitClassName = "";
                let isPending = submit_data.status === "pending";

                if (submit_data.status === "ok")  {
                  submitClassName = "green";
                }

                if (submit_data.status === "wrong_answer") {
                  submitClassName = "red";
                }

                if (submit_data.status === "pending") {
                  submitClassName = "gray";
                }

                return (
                  <div className={`submit submit-${submitClassName} mt-2 mb-4 border-2 border-black z-10 rounded-md px-2 py-2 flex-shrink-0`}>
                    <div class="submit-status">
                      {isPending && (<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>)}
                      Статус: {submit_data.status}
                    </div>
                    {!isPending && (<div>{submit_data.date}</div>)}
                  </div>
                )
              })}
              {/* <div className="submit submit-green mt-2 mb-4 border-2 border-black z-10 rounded-md px-2 py-2 flex-shrink-0">
                  <div class="submit-status">Статус: Accepted</div>
                  <div>Дата: 21.05.1996 10:14UTC</div>
              </div>
              <div className="submit submit-red mb-4 border-2 border-black z-10 rounded-md px-2 py-2 bg-white flex-shrink-0">
                  <div class="submit-status">Статус: Wrong answer</div>
                  <div>Дата: 21.05.1996 10:14UTC</div>
              </div>
              <div className="submit submit-gray mb-4 border-2 border-black z-10 rounded-md px-2 py-2 bg-white flex-shrink-0">
                  <div class="submit-status">Статус: Pending</div>
                  <div>Дата: 21.05.1996 10:14UTC</div>
              </div> */}
            </div>
            {/* <OutputWindow outputDetails={outputDetails} />
            <div className="flex flex-col items-end">
              <CustomInput
                customInput={customInput}
                setCustomInput={setCustomInput}
              />
              <button
                onClick={handleCompile}
                disabled={!code}
                className={classnames(
                  "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                  !code ? "opacity-50" : ""
                )}
              >
                {processing ? "Processing..." : "Compile and Execute"}
              </button>
            </div> */}
            {/* {outputDetails && <OutputDetails outputDetails={outputDetails} />} */}
          </div>
          <Modal show={showModal}>
            <Modal.Header>
                <Modal.Title>
                  {modalContent == "congrats" && "Congrats!"}
                  {modalContent == "request_submit_error" && "Error during the request."}
                </Modal.Title>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
              {modalContent == "congrats" && "The problem was solved! You can go to the next part!"}
              {modalContent == "request_submit_error" && "Submit has failed. Please try again."}
            </Modal.Body>
        </Modal>
        </div>
    </>
  );

  return (
    <> 
      {problemNotFound && (
        <div class="d-flex justify-content-center mt-10">
          Задача не существует или недоступна.
        </div>
        )}
      {isLoading && (
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      )}
      {!isLoading && !problemNotFound && (problemContent)}
    </>
  );
};
export default Problem;
