import './ProfilePage.css'
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const { user, setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [courses, setCourses] = useState([]);
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  const loadProfile = () => {
    setIsLoading(true);

    const options = {
      method: "GET",
      url: `/api/v0/get_user_profile/`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);

        setUserProfile(response.data.profile);
        setCourses(response.data.courses_data);
        setOrders(response.data.orders_data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadProfile();
  }, []);

  const handleLogout = () => {
    const options = {
      method: "GET",
      url: `/api/v0/logout/`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios
      .request(options)
      .then(function (response) {
        setAuth(false);
        navigate('/');
      });
  }

  return (
    <div>
        {/* <header class="py-5 bg-light">
                <div class="container px-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xxl-6">
                            <div class="text-center my-5">
                                <h1 class="fw-bolder mb-3">course name</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}
            {/* <section class="py-2 bg-light" id="scroll-target">
                <div class="container">
                    <div class="accordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-{{ forloop.counter }}" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    <i class="bi bi-check-circle-fill" style={{"color": "gray"}}></i> &nbsp; Lesson 1. Welcome captain Py.
                                </button>
                            </h2>
                            <div id="panelsStayOpen-{{ forloop.counter }}" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                <div class="accordion-body">
                                    <div class="container px-5 my-5">
                                        <div class="row gx-5 align-items-center">
                                            <div class="col-lg-6">
                                                <iframe src="https://kinescope.io/embed/200663469" width="100%" height="400px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                            <div class="col-lg-6">
                                                <h2 class="fw-bolder">Lesson 1. Welcome captain Py.</h2>
                                                <div class="row gx-5 row-cols-1 row-cols-md-2">
                                                    <div class="col mb-5 h-100">
                                                        <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-collection"></i></div>
                                                        <h2 class="h5">Featured title</h2>
                                                        <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                                                    </div>
                                                    <div class="col mb-5 h-100">
                                                        <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-building"></i></div>
                                                        <h2 class="h5">Featured title</h2>
                                                        <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                                                    </div>
                                                    <div class="col mb-5 mb-md-0 h-100">
                                                        <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                                        <h2 class="h5">Featured title</h2>
                                                        <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                                                    </div>
                                                    <div class="col h-100">
                                                        <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                                        <h2 class="h5">Featured title</h2>
                                                        <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                                                    </div>
                                                </div>
                                                <p class="lead fw-normal text-muted mb-0">
                                                    <div class="fw-normal text-muted" style={{"margin": "20px 0", "fontSize": "20px;"}}>
                                                        Coding problems:
                                                    </div>
                                                    <ul class="list-unstyled mb-4" style={{"fontSize": "20px"}}>
                                                        <li class="mb-2">
                                                            <i class="bi bi-file-bar-graph text-primary"></i>
                                                            Определение термина <strong>инвестиции</strong>
                                                        </li>
                                                        <li class="mb-2">
                                                            <i class="bi bi-wallet text-primary"></i>
                                                            В какие инструменты можно инвестировать
                                                        </li>
                                                        <li class="mb-2">
                                                            <i class="bi bi-currency-exchange text-primary"></i>
                                                            Сколько можно заработать, инвестируя в рынок акций
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section class="h-100 gradient-custom-2">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                    <div class="col col-lg-9 col-xl-7">
                        <div class="card">
                        {/* <div class="rounded-top text-white d-flex flex-row" style={{"backgroundColor": "#000", "height": "200px"}}>
                            <div class="ms-4 mt-5 d-flex flex-column" style={{"width": "150px"}}>
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
                                alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
                                style={{"width": "150px", "zIndex": "1"}}/>
                            <button type="button" class="btn btn-outline-dark" data-mdb-ripple-color="dark"
                                style={{"zIndex": "1"}}>
                                Edit profile
                            </button>
                            </div>
                            <div class="ms-3" style={{"marginTop": "130px"}}>
                            <h5>Andy Horwitz</h5>
                            <p>New York</p>
                            </div>
                        </div> */}
                        {/* <div class="p-4 text-black" style={{"backgroundColor": "#f8f9fa"}}>
                            <div class="d-flex justify-content-end text-center py-1">
                            <div>
                                <p class="mb-1 h5">253</p>
                                <p class="small text-muted mb-0">Photos</p>
                            </div>
                            <div class="px-3">
                                <p class="mb-1 h5">1026</p>
                                <p class="small text-muted mb-0">Followers</p>
                            </div>
                            <div>
                                <p class="mb-1 h5">478</p>
                                <p class="small text-muted mb-0">Following</p>
                            </div>
                            </div>
                        </div> */}
                        <div class="card-body p-4 text-black">
                            <div class="mb-5">
                            <p class="lead fw-normal justify-content-between col-md-12 d-flex mb-0">My profile <button type="button" class="btn btn-dark ml-auto" onClick={handleLogout}>Logout</button></p>
                            <div class="p-4" style={{"backgroundColor": "#f8f9fa;"}}>
                                <p class="font-italic mb-1">Name: {!isLoading && userProfile && userProfile.name}</p>
                                <p class="font-italic mb-1">Email: {!isLoading && userProfile && userProfile.email}</p>
                            </div>
                            <p class="lead fw-normal mb-1">My courses</p>
                            <div class="p-4" style={{"backgroundColor": "#f8f9fa;"}}>
                                {courses.length == 0 && (
                                    <div>No courses</div>
                                )}
                                {courses.map(course => (
                                    <div>
                                        {course.course__slug}
                                    </div>
                                ))}
                            </div>
                            <p class="lead fw-normal mb-1">My purchases (transactions)</p>
                                <div class="p-4" style={{"backgroundColor": "#f8f9fa;"}}>
                                    {orders.length == 0 && (
                                        <div>No purchases</div>
                                    )}
                                    {orders.length != 0 && (
                                        <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Info</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.map((order, idx) => (
                                                <tr>
                                                    <td>{idx}</td>
                                                    <td>{order.status}</td>
                                                    <td>{order.order_info}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                            {/* <div class="d-flex justify-content-between align-items-center mb-4">
                            <p class="lead fw-normal mb-0">Recent photos</p>
                            <p class="mb-0"><a href="#!" class="text-muted">Show all</a></p>
                            </div>
                            <div class="row g-2">
                            <div class="col mb-2">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(112).webp"
                                alt="image 1" class="w-100 rounded-3"/>
                            </div>
                            <div class="col mb-2">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(107).webp"
                                alt="image 1" class="w-100 rounded-3"/>
                            </div>
                            </div>
                            <div class="row g-2">
                            <div class="col">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(108).webp"
                                alt="image 1" class="w-100 rounded-3"/>
                            </div>
                            <div class="col">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(114).webp"
                                alt="image 1" class="w-100 rounded-3"/>
                            </div> */}
                            {/* </div> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
    </div>
  );
};

export default ProfilePage;