import './MainPage.css'
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../AuthContext';
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
  const { user, setAuth } = useAuth();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [modelContent, setModelContent] = useState("");
  const [activationCode, setActivationCode] = useState('');
  
  const navigate = useNavigate();

  const handleCodePythonBeginner = () => {
    if (!user) {
        navigate('/login');
    }
    setModelContent("code_input");
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleSendPythonBeginnerCode = () => {
    const registerData = {
      code: activationCode,
      course: 'python_beginner'
    };
    const csrfToken = document.cookie.split('; ')
      .find(cookie => cookie.startsWith('csrftoken='))
      .split('=')[1];
  
    axios.post(
      '/api/v0/send_course_promocode/',
       registerData,
       {
        headers: {
          'X-CSRFToken': csrfToken,
        }
      })
      .then(response => {
        if (response.data.status == "success") {
            setModelContent("main_page_success");
        } 
        if (response.data.status == "error") {
            setModelContent("main_page_" + response.data.error_code);
        }

        setShowModal(true);
      })
  }

  return (
    <div>
        {/* <header class="bg-dark py-5">
            <div class="container px-5">
                <div class="row gx-5 align-items-center justify-content-center">
                    <div class="col-lg-8 col-xl-7 col-xxl-6">
                        <div class="my-5 text-center text-xl-start">
                            <h1 class="display-5 fw-bolder text-white mb-2">Kopilka.pro</h1>
                            <p class="lead fw-normal text-white-50 mb-4">{t('programming_courses_fedor')}</p>
                            <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                <a class="btn btn-primary btn-lg px-4 me-sm-3" href="#features">Купить сейчас со скидкой 30%</a>
                                <a class="btn btn-outline-light btn-lg px-4" href="#!">Узнать подробнее</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img class="img-fluid rounded-3 my-5" src="https://dummyimage.com/600x400/343a40/6c757d" alt="..." /></div>
                </div>
            </div>
        </header> */}
        <section class="bg-light py-5">
            <div class="container px-5 my-5">
                {/* <div class="text-center mb-5">
                    <h1 class="fw-bolder">Купить курс</h1>
                </div> */}
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-6 col-xl-4">
                        <div class="card mb-5 mb-xl-0">
                            <div class="card-body p-5">
                                <div class="small text-uppercase fw-bold">Python для начинающих с нуля</div>
                                <div class="mb-3">
                                    <span class="display-4 fw-bold">₽3999</span>
                                    {/* <span class="text-muted">/ mo.</span> */}
                                </div>
                                <ul class="list-unstyled mb-4">
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        <strong>Получите полное представление о базовых конструкциях языка</strong>
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Интересная теория
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Большое число задач
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Автоматическая проверка задач
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-x"></i>
                                        Прикладные знания
                                    </li>
                                    {/* <li class="mb-2 text-muted">
                                        <i class="bi bi-x"></i>
                                        Dedicated support
                                    </li>
                                    <li class="mb-2 text-muted">
                                        <i class="bi bi-x"></i>
                                        Free linked domain
                                    </li>
                                    <li class="text-muted">
                                        <i class="bi bi-x"></i>
                                        Monthly status reports
                                    </li> */}
                                </ul>
                                <div class="d-grid"><a class="btn btn-dark mb-2 disabled" href="#!">Купить (временно недоступно)</a></div>
                                <div class="d-grid"><a class="btn btn-outline-dark" onClick={handleCodePythonBeginner}>Ввести код доступа</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4">
                        <div class="card mb-5 mb-xl-0">
                            <div class="card-body p-5">
                                <div class="small text-uppercase fw-bold">
                                    <i class="bi bi-star-fill text-warning"></i>
                                    Продвинутый Python
                                </div>
                                <div class="mb-3">
                                    <span class="display-4 fw-bold">₽5999</span>
                                    {/* <span class="text-muted">/ mo.</span> */}
                                </div>
                                <ul class="list-unstyled mb-4">
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        <strong>Углубленное изучение деталей языка</strong>
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Интересная теория
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Еще больше задач
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Автоматическая проверка
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Прикладные знания
                                    </li>
                                    {/* <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Dedicated support
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Free linked domain
                                    </li>
                                    <li class="text-muted">
                                        <i class="bi bi-x"></i>
                                        Monthly status reports
                                    </li> */}
                                </ul>
                                <div class="d-grid"><a class="btn btn-dark mb-2 disabled" href="#!">В разработке (недоступно)</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4">
                        <div class="card">
                            <div class="card-body p-5">
                                <div class="small text-uppercase fw-bold">Базовые алгоритмы на Python</div>
                                <div class="mb-3">
                                    <span class="display-4 fw-bold">₽7890</span>
                                </div>
                                <ul class="list-unstyled mb-4">
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        <strong>Алгоритмы и структуры данных</strong>
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Интересная теория
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Еще больше задач
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Автоматическая проверка
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Прикладные знания
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Разные области алгоритмов
                                    </li>
                                    {/* <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        Dedicated support
                                    </li>
                                    <li class="mb-2">
                                        <i class="bi bi-check text-primary"></i>
                                        <strong>Unlimited</strong>
                                        linked domains
                                    </li>
                                    <li class="text-muted">
                                        <i class="bi bi-check text-primary"></i>
                                        Monthly status reports
                                    </li> */}
                                </ul>
                                <div class="d-grid"><a class="btn btn-dark mb-2 disabled" href="#!">В разработке (недоступно)</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section class="py-5" id="features">
            <div class="container px-5 my-5">
                <div class="row gx-5">
                    <div class="col-lg-4 mb-5 mb-lg-0"><h2 class="fw-bolder mb-0">A better way to start building.</h2></div>
                    <div class="col-lg-8">
                        <div class="row gx-5 row-cols-1 row-cols-md-2">
                            <div class="col mb-5 h-100">
                                <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-collection"></i></div>
                                <h2 class="h5">Featured title</h2>
                                <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                            </div>
                            <div class="col mb-5 h-100">
                                <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-building"></i></div>
                                <h2 class="h5">Featured title</h2>
                                <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                            </div>
                            <div class="col mb-5 mb-md-0 h-100">
                                <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                <h2 class="h5">Featured title</h2>
                                <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                            </div>
                            <div class="col h-100">
                                <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                <h2 class="h5">Featured title</h2>
                                <p class="mb-0">Paragraph of text beneath the heading to explain the heading. Here is just a bit more text.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <div class="py-5 bg-light">
            <div class="container px-5 my-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-10 col-xl-7">
                        <div class="text-center">
                            <div class="fs-4 mb-4 fst-italic">"Working with Start Bootstrap templates has saved me tons of development time when building new projects! Starting with a Bootstrap template just makes things easier!"</div>
                            <div class="d-flex align-items-center justify-content-center">
                                <img class="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..." />
                                <div class="fw-bold">
                                    Tom Ato
                                    <span class="fw-bold text-primary mx-1">/</span>
                                    CEO, Pomodoro
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <Modal show={showModal}>
            <Modal.Header>
                <Modal.Title>
                    {modelContent == "code_input" ? t('main_page_enter_access_code') : t('main_page_notification')}
                </Modal.Title>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {modelContent == "code_input" && (
                    <div class="d-flex justify-content-around">
                        <input type="text" class="form-control mb-0" value={activationCode} onChange={(e) => setActivationCode(e.target.value)} />
                        <button type="button" class="btn btn-dark" onClick={handleSendPythonBeginnerCode}>{t('main_page_send_code')}</button>
                    </div>
                )}
                {modelContent != "code_input" && (
                    <div class="d-flex justify-content-around">
                        {t(modelContent)}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    </div>
    
  );
};

export default MainPage;