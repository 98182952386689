import { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";

const AuthContext = createContext({
  auth: null,
  setAuth: () => {},
  user: null,
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  

  useEffect(() => {
    const options = {
        method: "GET",
        url: `/api/v0/get_user/`,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      axios
        .request(options)
        .then(function (response) {
            console.log('user', response);
            if (response.data.user) {
                setUser(response.data.user);
            } else {
                setUser(null)
            }
        }).catch(e => setUser(null));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;